import React from 'react';
import ImageViewer from './ImageViewer'
import AudioViewer from './AudioViewer'
import VideoViewer from './VideoViewer'
import VideoWistiaViewer from './VideoWistiaViewer'
// import ReactToPrint from 'react-to-print';


export default function MediaView(props) {


    const icon = props.icon

    return (
        
			
                    <>
                    
                    
                    {icon.type === "image" && (
                            <ImageViewer img_url={icon.imageURL} img_name={icon.name} />

                    )}
                    
                    {icon.type === "audio" && (
                            <AudioViewer audio_url={icon.audio_url} audio_name={icon.name}/>

                    )}

                     {icon.type === "video" && (
                            <VideoViewer video_url={icon.video_url} ></VideoViewer>

                    )}

                     {icon.type === "wistia-video" && (
                           <VideoWistiaViewer video_url={icon.videoID} ></VideoWistiaViewer>

                    )}

                    
                  </>
                    
    			
       
    )
}



