import React, { useState } from 'react';

import ChatConversations from  './ChatConversations'
import ConversationAKJPQ from  './ConversationAKJPQ'
import ConversationYNWZZPK from  './ConversationYNWZZPK'
import ImageViewer from '../ImageViewer' 

export default function MessagesView(props) {
	
	const [selectedConversation, setSelectedConversation] = useState(-1) 
	const [selectedImage, setSelectedImage] = useState(-1) 

	
	const tapBack = () => {
	  setSelectedConversation(-1)
	}

	const setCurrentConversation = (number) => {
  	
	  	setSelectedConversation(number)
	}


	const setSelectedImageToView = (imageUrl) => {
		setSelectedImage(imageUrl)
	}

	const tapBackImageViewer = () => {
		setSelectedImage(-1)
	}

    return (
        
		<>
			<div className="header"> 
				{selectedConversation === -1 ? 
					(
						<>
							<h1 className="mobile">Messages</h1>
							<div className="flex flex-row m-auto items-center desktop "><div className="mr-4 avatar">A</div><h1 >AKJPQ</h1></div>
						</>

					) : (
					
						<div className="flex flex-row m-auto items-center "><div className="mr-4 avatar" >{selectedConversation[0]}</div><h1 >{selectedConversation}</h1></div>
					)
				
				}

			</div>



	  		{selectedImage === -1 ? 
			(

				<div className="keepsafe-container message-container-content" >
					<div  className={selectedConversation === -1 ? "menuStorage" : "menuStorage desktop"} >
						<ChatConversations selectedConversation={selectedConversation} setCurrentConversation={setCurrentConversation.bind(this)}/>
					</div>
			      
			    	<div className={selectedConversation === -1 ? "keepsafe-container-content desktop" : "keepsafe-container-content"}>
			    		<div className = "HAKEmailChatContainer">
			    			<div className="mt-1 mb-2 container">
					    		{(selectedConversation === "AKJPQ" || selectedConversation === -1) ?
					    			(
					    				<ConversationAKJPQ setSelectedImageToView={setSelectedImageToView.bind(this)} />
					    			) : (
					    				<ConversationYNWZZPK />
					    			)
					    		
					    		}
					    	</div>

				    		<form className="">
				    			<div className="form-group">
				    				<div className="position-relative flex items-center">
				    					<input name="message" className="rounded-pill hak-chat-input form-control" value="" disabled={true} />
				    					<span className="add-button-chat ml-10">
				    						<img alt='Add' src="https://cdn.huntakiller.com/huntakiller/s10/ashelysofflinemessage/add-icon-yellow.svg" />
				    					</span>
				    				</div>
				    			</div>
				    		</form>
				    	</div>
			    	</div>

			    	{selectedConversation !== -1 && (<img className="back-arrow mobile" alt="back-arrow" onClick={tapBack} src="https://cdn.huntakiller.com/huntakiller/s10/back-arrow-white.svg"/>)}
		    	</div>




			) : (
				<div className="keepsafe-container message-container-content" >
					<ImageViewer img_url={selectedImage} img_name={""} />

					<img className="back-arrow " alt="back-arrow" onClick={tapBackImageViewer} src="https://cdn.huntakiller.com/huntakiller/s10/back-arrow-white.svg"/>
		    	

				</div>

			)}

		       



		  	

		  

		 </>
                
    )
}



