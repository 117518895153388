import React from 'react';

export default function ChatConversations(props) {

  const selectedConversation = props.selectedConversation
  return (
  	<>

  	<h2 className="desktop">MESSAGES</h2>

  	<div className={selectedConversation === -1 ? "defaultSelected chat-thread flex flex-row items-center py-6 px-4 cursor-pointer " : ( selectedConversation === 1 ? "selected chat-thread flex flex-row items-center py-6 px-4 cursor-pointer" : "chat-thread flex flex-row items-center py-6 px-4 cursor-pointer")} onClick={function(){props.setCurrentConversation(1)}}>

  		<img className="pr-6" src="https://cdn.huntakiller.com/huntakiller/s10/chat_frannie_avatar.svg" alt='Frannie' />

  		<div>
  			<p className="userName font-medium md:text-xl">Frannie</p>
  		</div>
  	</div>


    {props.currentEpisode === 6.3 &&  (

        <div className={selectedConversation === 2 ? "selected chat-thread flex flex-row items-center py-6 px-4 cursor-pointer " : "chat-thread flex flex-row items-center py-6 px-4 cursor-pointer" } onClick={function(){props.setCurrentConversation(2)}}>

          <img className="pr-6" src="https://cdn.huntakiller.com/huntakiller/s10/chat_reggie_avatar.svg" alt='Reggie' />

          <div>
            <p className="userName font-medium md:text-xl">Reggie</p>
          </div>
      </div>


      )}

  	

  	</>
  
  );
  
}


/*



  	


  	*/