import React, { Component } from 'react';

import { Player } from './models/player';
import ChatClient from './components/ChatClient/chat-client';
var sendKlaviyoEvent = require('./utils/klaviyo.js').sendKlaviyoEvent;

export default class Inbox extends Component {
    constructor(props) {
        super(props);
        this.chatClientRef = React.createRef();
        this.currentSeasonIndex = 10;

        this.state = {
            showEmailClient: true,
            userEmail: this.props.userEmail,
            stepsPerEpisode: [],
            currentEpisodeIndex: this.props.currentEpisode,
        };

        // Fetch the configuration data
        fetch('./config/config_10.json')
            .then(response => response.json())
            .then(data => {
                this.setState({ stepsPerEpisode: data.botConfiguration.stepsPerEpisode });
            })
            .catch(err => {
                console.error('Error fetching configuration:', err);
            });
    }

    triggerEmail = (message) => {
        if (this.chatClientRef.current) {
            this.chatClientRef.current.loadBridgeEmailGroupAnswer(message);
        }
    };

    closeModal = () => {
        this.props.tapBack();
    };

    updateGameContext = (newStep) => {
        console.log('New Step: ' + newStep);
        this.setState({ currentEpisodeIndex: newStep });
        if (this.chatClientRef.current) {
            this.chatClientRef.current.updateGameContext(newStep);
            if (newStep === 6.3) {
                this.chatClientRef.current.sendEpilogueMessage();
            }
        }
    };

    handleBotEmailResponse = (botResponse) => {
        const correctQuestion = botResponse.indexOf('{{CorrectQuestion}}');
        this.props.newMessageHandler();

        if (correctQuestion !== -1) {
            let newStep = this.state.currentEpisodeIndex + (Number.isInteger(this.state.currentEpisodeIndex) ? 0.2 : 0.1);
            this.props.updateEpisode(newStep);
            console.log('New Step: ' + newStep);
            
            if (this.state.stepsPerEpisode[Math.floor(this.state.currentEpisodeIndex)] > 1) {
                const maxStep = Math.floor(this.state.currentEpisodeIndex) + this.state.stepsPerEpisode[Math.floor(this.state.currentEpisodeIndex)] / 10;
                if (this.state.currentEpisodeIndex < maxStep) {
                    if (newStep === 6.2) {
                        const result = this.chatClientRef.current?.sendKillSwitchMessage();
                    }
                    sendKlaviyoEvent("HAK100" + parseInt(this.state.currentEpisodeIndex), this.state.userEmail);
                    this.setState({ currentEpisodeIndex: newStep });
                    const updated = this.chatClientRef.current?.updateGameContext(newStep);
                    console.log('Updated: ' + updated);
                } else {
                    sendKlaviyoEvent(this.state.currentEpisodeIndex, this.state.userEmail);
                    const event = new CustomEvent('CHARACTERRESPONSE', {
                        detail: {
                            season: "hak10",
                            episode: "0" + parseInt(this.state.currentEpisodeIndex),
                            success: "hak10" + "0" + parseInt(this.state.currentEpisodeIndex),
                        },
                    });
                    window.dispatchEvent(event);
                }
            } else {
                sendKlaviyoEvent(this.state.currentEpisodeIndex, this.state.userEmail);
                const event = new CustomEvent('CHARACTERRESPONSE', {
                    detail: {
                        season: "hak10",
                        episode: "0" + parseInt(this.state.currentEpisodeIndex),
                        success: "hak10" + "0" + parseInt(this.state.currentEpisodeIndex),
                    },
                });
                window.dispatchEvent(event);
            }
        } else {
            console.log('Incorrect');
        }
    };

    handlepenAttachement = (id) => {
        this.props.openAttachement(id);
    };

    render() {
        const renderEmailClient = () => {
            if (this.state.showEmailClient) {
                const player = new Player();
                player.email = this.state.userEmail;

                const configurationLocation = '/config/config_10.json';

                return (
                    <ChatClient
                        player={player}
                        configurationLocation={configurationLocation}
                        botChatResponse={this.handleBotEmailResponse}
                        currentEpisode={this.state.currentEpisodeIndex}
                        openAttachement={this.handlepenAttachement}
                        ref={this.chatClientRef}
                    />
                );
            }
            return null;
        };

        return (
            <div className={this.props.classToSet ? this.props.classToSet + " HAKEmailChatContainer" : "HAKEmailChatContainer"}>
                <div className="header-chat text-center pb-4">
                    <img className="mx-auto" src="https://cdn.huntakiller.com/huntakiller/s10/chat_frannie_avatar.svg" alt="Frannie" />
                    <p className="userName text-white font-medium font-bold md:text-xl">Frannie</p>
                </div>
                {this.props.userEmail !== undefined && renderEmailClient()}
            </div>
        );
    }
}