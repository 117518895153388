
import React from "react";

export default function BlogComment(props) {

  const comment = props.comment


  return (

  <div className="comment-container flex flex-row items-center py-8 px-4 md:px-0"> 

  	<img className="avatar mr-4" src={"https://cdn.huntakiller.com/huntakiller/"+comment.avatarURL} alt="Avatar" />
	 
	<div>
		<p className="font-bold text-base mb-2">{comment.author}</p>
		<div className="text-base" dangerouslySetInnerHTML={{ __html: comment.message }}></div>
	</div>


  </div>);
  
}