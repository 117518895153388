import React from 'react';

export default function ConversationAKJPQ(props) {

  return (
  	<>


      <div className="timestamp my-2">1:50 PM</div>
  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-received col-7">
  				<div className="flex flex-row items-center cursor-pointer" onClick={function(){props.setSelectedImageToView("s10/digital-files/offlinecontactphoto1.jpg")}}>
  					<img src="https://cdn.huntakiller.com/huntakiller/s10/ashelysofflinemessage/chat-jpg-icon.svg" className="chat-jpg" alt='Ashely' />
  					<div className="flex-1"><p className="text-center"><b>photo1.jpg</b><br/>click to view</p></div>
  				</div>

  			</div>
  		</div>
  	
  	
  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-sent col-7 offset-5">? Is that TFC?</div>
  		</div>

  	
  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-received col-7">Yes</div>
  		</div>

  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-received col-7">will explain more when we meet</div>
  		</div>


  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-sent col-7 offset-5">we're meeting at the library right?</div>
  		</div>
  	
  		<div className="mb-3 align-top row no-gutters">
  			<span className="emoji" role="img" aria-label="thumbs-up-imoji">👍</span>
  		</div>

  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-sent col-7 offset-5">I know it’s a big leap for you to take, so thank you.</div>
  		</div>

  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-sent col-7 offset-5">This won’t go to waste -- you’re helping Copper Cliffs.</div>
  		</div>
      <div className="timestamp my-2">2:03 PM</div>
  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-received col-7">thx</div>
  		</div>
      <div className="timestamp my-2">6:23 PM</div>
  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-received col-7">ive had enough</div>
  		</div>

  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-received col-7">im no pushover</div>
  		</div>

  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-received col-7">people are gonna have to face the truth about me</div>
  		</div>

  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-received col-7">youve gotta promise you wont mention me</div>
  		</div>

  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-sent col-7 offset-5">I promise no harm will come to you from my end -- I never reveal my sources.</div>
  		</div>
      <div className="timestamp my-2">6:54 PM</div>
  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-received col-7">yeah ive had enough tfc can suck it hard</div>
  		</div>

  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-received col-7">screw him</div>
  		</div>

  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-received col-7">im tired of people walking on me to get to the top</div>
  		</div>

  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-received col-7">he cant say i didnt warn him</div>
  		</div>

  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-received col-7">or give him chances</div>
  		</div>

  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-received col-7">every single person in his campaign is rotten</div>
  		</div>

  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-received col-7">they all deserve to go down in flames</div>
  		</div>

  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-sent col-7 offset-5">I’ll meet you later at the library as planned, then.</div>
  		</div>

  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-received col-7">you cant tell ANYBODY i told you</div>
  		</div>
      <div className="timestamp my-2">7:01 PM</div>
  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-sent col-7 offset-5">You have my word.</div>
  		</div>
      <div className="timestamp my-2">7:54 PM</div>
  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-received col-7">here</div>
  		</div>
      <div className="timestamp my-2">8:00 PM</div>
  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-received col-7">where r u</div>
  		</div>

  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-received col-7">hello?? if u dont show soon im leaving</div>
  		</div>
      <div className="timestamp my-2">8:10 PM</div>
  		<div className="mb-3 align-top row no-gutters">
  			<div className=" p-3 hak-chat-received col-7">leaving</div>
  		</div>
  	</>
  
  );
  
}
