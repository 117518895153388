import React, { useState, useEffect, useRef } from 'react';
import store from 'store';
import { useHistory } from 'react-router-dom';
import Div100vh from 'react-div-100vh'
import HomeView from './HomeView'
import StorageView from "./StorageView"
import MessagesView from "./MessagesView"

import Header from '../components/Header'

// import StorageMenuContent from '../components/StorageMenuContent';
// import CheeseburgerMenu from '../components/CheeseburgerMenu';

// HAK styles
import '../styles/app.scss';
import '../components/EmailClient/email-client.scss';

// var getGetChildrenFromFolder = require('../utils.js').getGetChildrenFromFolder;

export default function DesktopView(props) {
  const storageViewRef = useRef(null); 
  const messageViewRef = useRef(null); 
  const history = useHistory();
  // const icons = props.icons
  const [currentEpisode, setcurrentEpisode] = useState(props.currentEpisode)
  const [currentView, setCurrentView] = useState("home")
  const [storageMenuOpen, setStorageMenuOpen] = useState(false)
  const [showNewMessagePopUp, setShowMessagePopUp] = useState(false)
  const setView = (name) =>{
    setCurrentView(name)
  }

  const logout = () => {
    store.remove('passwordHAK10')
    history.push("/")
  }

  

  const updateEpisodeFromMessage = (newEpisode) => {
    setcurrentEpisode(newEpisode)
    storageViewRef.current.updateEpisode(newEpisode)
  }

  const updateEpisodeFromStorage = (newEpisode) => {
    setcurrentEpisode(newEpisode)
    messageViewRef.current.updateEpisode(newEpisode)
  }

  const openAttachement = (id) => {
    setCurrentView("storage")
    storageViewRef.current.handleClickIcon(id)

  }

  const newMessageHandler = () => {
     
     if(currentView !== "messages") { 
        setShowMessagePopUp(true)

      
     }
  }

 
    useEffect(() => { 
      //console.log("useEffect useEffect")
      //console.log(currentView)
      if(currentView==="messages" && document.getElementById("messagesEnd") !== null) {
        document.getElementById("messagesEnd").scrollIntoView({ behavior: "smooth" });
      }

    },[currentView])


  return (
    <>
   
    {currentView === "home" && ( 
      <HomeView tapButton={setView.bind(this)} logout={logout.bind(this)}/>
    )}

      <Div100vh  dontresize="true"  className="h-full w-full flex z-10 flex-col relative" >

          <Header  tapButton={setView.bind(this)} currentView={currentView}  logout={logout.bind(this)} />

          <div className="main-container" style={{display: currentView ==="storage" ? 'flex' : 'none'}}>
              <StorageView ref={storageViewRef}  updateEpisode={updateEpisodeFromStorage.bind(this)}  tapMenu={function(){setStorageMenuOpen(false); setCurrentView("storage")}} currentEpisode={currentEpisode} />
          </div>

          <div className="main-container" style={{display: currentView ==="messages" ? 'flex' : 'none'}}>
              <MessagesView ref={messageViewRef} currentEpisode={currentEpisode} updateEpisode={updateEpisodeFromMessage.bind(this)} openAttachement={openAttachement.bind(this)}  newMessageHandler={newMessageHandler.bind(this)} />
          </div>


          {showNewMessagePopUp && (

              <div className="newMessagePopUp">
                <h1>New Message</h1>
                <p>you received a new message!</p>
                <div className="flex flex-row">
                  <button className="rounded-full mx-2  w-full text-white font-bold py-2 px-2 rounded focus:outline-none focus:shadow-outline" onClick={function() {setShowMessagePopUp(false);}}>
                Cancel
                  </button>

                  <button className="rounded-full mx-2  w-full text-white font-bold py-2 px-2 rounded focus:outline-none focus:shadow-outline" onClick={function() {setShowMessagePopUp(false);setCurrentView("messages");}}>
                Go
                  </button>

                </div>
              </div>

          )}

      </Div100vh>

    

    </>


  )




}