import React from 'react';

export default function ReggieEpilogueChat(props) {

  
  return (
  	<div className={props.classToSet ? props.classToSet+" HAKEmailChatContainer" : "HAKEmailChatContainer"}>
        <div className=" header-chat text-center  pb-4">
          <img className="mx-auto" src="https://cdn.huntakiller.com/huntakiller/s10/chat_reggie_avatar.svg" alt="Reggie"/>
          <p className="userName text-white font-medium font-bold md:text-xl">Reggie</p>
        </div>

        <div className=" pt-4 mb-2 container">
          <div className="mb-3 align-top row no-gutters">
            <div className=" p-3 hak-chat-received col-9">
Hi, detective.<br/>
Sorry if I still sound a little shaken up. Frannie's message really scared me.<br/>
And to think that Kim almost got to her.<br/>
And for what? To make sure that deal with Valdivian went through and she got her money?<br/>
It seems like when Ash started looking into the car crash, she accidentally got too close to something even bigger<br/>
Turns out we were right all along about what TFC and Valdivian were up to<br/>
And I guess Kim was willing to do whatever it took to keep it from getting out<br/>
Was protecting her payout really worth killing two people for?<br/>
And almost a third?<br/>
It scares me to consider what would have happened if Frannie hadn't set up that message, or what could have happened if you hadn't uploaded all of our evidence to the blog.<br/>
You're a real hero, detective. You really brought justice to us all.<br/>
Me, Frannie, Ash, the town.<br/>
Even the families of Morgana Wilkes and Isabel King, the women killed in the crash all those years ago, will be grateful for what you've done<br/>
Docs say that Frannie will be just fine, she's just hopped up on painkillers, so she's a little loopy.<br/>
She has a broken clavicle, but that's about it.<br/>
I think I'm going to ask her to move in full-time. I hate being alone in the apartment, and she's the only one I would feel comfortable living in Ashley's room<br/>
I can't thank you enough for finding out what happened to my sister, and helping to finish the work she began by exposing the corruption that was rotting this town from the inside out.<br/>
This entire nightmare has been heartbreaking and terrifying, but I'm grateful that I've finally gotten closure about Ash's death.<br/>
I'd give anything for her to be here right now, and see how everything turned out.<br/>
If you're ever in Copper Cliffs, come by the flower shop and say hi.<br/>
Frannie and I would love to take you out for a burrito!
            </div>
          </div>

          <div style={{float: "left", clear: "both"}}></div>

        </div>

        <form className="">
          <div className="form-group">
            <div className="position-relative">
              <input className="message rounded-pill hak-chat-input form-control" id="message" value="" disabled />
              <span className="position-absolute send-button-chat disabled">
                <img src="https://cdn.huntakiller.com/huntakiller/s10/chat-send-button-icon.svg" alt='Send Button' />
              </span>
            </div>
          </div>
        </form>
      </div>
  
  );
  
}


/*



  	


  	*/