/**
 * Configuration service
 *
 * Overview: Provides Configuration service that reads external configuration file.
 *
 */

export class ConfigurationService {
    /**
     * Gets configuration file.
     * @param configurationLocation Configuration file location.
     * @returns Status 200 if returned
     */
    get(configurationLocation: string) {
        return new Promise((resolve, reject) => {
            fetch(configurationLocation, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            }).then((response) => {
                if (response.ok) {
                    return resolve(response);
                } else {
                    return reject(response);
                }
            });
        });
    }
}
