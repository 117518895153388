import React, { Component } from 'react'
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css'
import './blog.css'

export default class BlogExcerpt extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	      fetching:false,
	    };

	}
	
	componentWillMount () {
		if(this.props.blogEntry.excerptVideoWistiaId) {
			const script = document.createElement("script");

	    script.src = "https://fast.wistia.com/embed/medias/"+this.props.blogEntry.excerptVideoWistiaId+".jsonp"; ;
	    script.async = true;
	 
	    document.body.appendChild(script);

	    const script2 = document.createElement("script");
	 
	    script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
	    script2.async = true;
	 
	    document.body.appendChild(script2);
		}

	}


	download = (url, name, e) => {
		this.setState({fetching: true})
	};

	tapReadMore = () => {
		
		this.props.tapReadMore(this.props.blogEntry.id)
	}

	hideGallery = () => {
		this.setState({fetching: false})
	}

	render() {

		const blogEntry = this.props.blogEntry

		const images = [
			{
			  original: 'https://cdn.huntakiller.com/huntakiller/s10/digital-files/drawer-photo1.jpg',
			  thumbnail: 'https://cdn.huntakiller.com/huntakiller/s10/icons/drawerphoto1icon.svg',
			},
			{
			  original: 'https://cdn.huntakiller.com/huntakiller/s10/digital-files/drawer-photo2.jpg',
			  thumbnail: 'https://cdn.huntakiller.com/huntakiller/s10/icons/drawerphoto2icon.svg',
			},
			{
			  original: 'https://cdn.huntakiller.com/huntakiller/s10/digital-files/drawer-photo3.jpg',
			  thumbnail: 'https://cdn.huntakiller.com/huntakiller/s10/icons/drawerphoto3icon.svg',
			},
		  ];

		if(this.state.fetching){
			return(<div className="blog_excerpt_container_gallery">
				<ImageGallery items={images} />
				<button style={{fontStyle: "bold", marginLeft: "10px"}} type="button" onClick={() => this.hideGallery()} className="btn btn-link">{"Hide Frannie_Files.zip"}</button>
				{/* <a href="#" style={{}} onClick={() => this.hideGallery()}>{"<< Hide Frannie Files"}</a> */}
			</div>)
		}
		else return ( 
			<div className="blog_excerpt_container pb-6 md:pb-10">
		{(blogEntry.imageURL) && (<img alt='Blog Entry' className="mt-3 md:mt-8" src={"https://cdn.huntakiller.com/huntakiller/"+blogEntry.imageURL} />)}
		<h3 className="font-bold text-2xl mt-2 mb-3 px-4 md:px-0 md:text-4xl md:mt-4 md:mb-6 ">{blogEntry.title}</h3>
		{blogEntry.id < 11 && ( <p className="text-sm px-4 md:px-0 md:text-lg">{blogEntry.excerpt} <span className='underline cursor-pointer orange font-bold' onClick={this.tapReadMore}>Read More{'>>'}</span></p>)}

		{blogEntry.id === 11 && (<img alt='Blog Entry Image' className="mt-3 ml-4 cursor-pointer md:ml-0 md:mt-8" src={"https://cdn.huntakiller.com/huntakiller/"+blogEntry.excerptImageURL}  onClick={(e)=> this.download("https://cdn.huntakiller.com/huntakiller/s10/digital-files/Frannie_Files.zip", "Frannie_Files.zip", e)}  download="Frannie_Files.zip" />)}

		{blogEntry.id === 12 && (<div className={"wistia_embed wistia_async_"+blogEntry.excerptVideoWistiaId+" px-4 md:px-0 videoFoam=true popover=true popoverAnimateThumbnail=true"} >
		                 </div>)}

		<p className="font-medium text-xs pt-2 px-4 md:px-0 md:text-lg md:pt-6">{"By "+blogEntry.author}</p>
		</div>

		)


	}
}